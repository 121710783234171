<template>
  <div>
    <v-toolbar flat height="80px">
      <v-toolbar-title class="text-h1">{{ siteName }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <div class="mt-4" id="DarkReconForumManager">
      <v-row>
        <v-row style="display: contents"
          ><v-col>
            <v-text-field
              placeholder="Filter Title"
              v-model="title"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              :items="[
                { name: 'All', value: null },
                { name: 'Enabled', value: true },
                { name: 'Disabled', value: false },
              ]"
              item-value="value"
              item-text="name"
              label="Enabled"
              v-model="enabled"
              @input="updateEnabled(enabled)"
            ></v-select>
          </v-col>

          <v-col>
            <v-select
              :items="[
                { name: 'Title', value: 'title' },
                { name: 'Last Activity Date', value: 'last_activity_date' },
                { name: 'Last Scraped Date', value: 'last_scraped_date' },
              ]"
              item-value="value"
              item-text="name"
              label="Sort by"
              v-model="sort_by"
              @input="updateSortBy(sort_by)"
            ></v-select>
          </v-col>
          <v-col>
            <caption
              style="
                font-size: 0.72em;
                width: 100px;
                margin-left: -2em;
                margin-bottom: 5px;
                color: rgba(255, 255, 255, 0.7);
              "
            >
              Sort order:
            </caption>
            <v-btn-toggle
              mandatory
              style="margin-right: 80px"
              v-model="toggleSort"
            >
              <v-btn small>
                <v-icon>mdi-sort-ascending</v-icon>
              </v-btn>
              <v-btn small>
                <v-icon>mdi-sort-descending</v-icon>
              </v-btn>
            </v-btn-toggle></v-col
          >

          <v-col cols="12">
            <v-toolbar flat class="mt-0">
              <v-toolbar-title class="text-h1"
                >Forums ({{ this.totalForums }})
              </v-toolbar-title>

              <v-spacer />
              <v-menu offset-y v-if="this.selectedForums.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="accent"
                    dark
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <v-icon left> mdi-menu-down </v-icon> Actions
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in ['Enable', 'Disable']"
                    :key="index"
                  >
                    <v-list-item-title
                      @click="updateForums(item)"
                      style="cursor: pointer"
                      >{{ item }}</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              <v-menu
                v-model="columnMenu"
                :close-on-content-click="false"
                bottom
                left
              >
                <v-card
                  style="min-width: 480px; min-height: 300px; padding: 0em"
                >
                  <v-layout row>
                    <v-flex
                      col
                      style="
                        color: #fff;
                        background: #58abe7;
                        min-height: 300px;
                        padding: 3em;
                      "
                    >
                      <v-row style="margin-top: -2em; margin-left: 0.5em">
                        <v-col>
                          <p
                            style="
                              color: #112748;

                              font-size: 0.8em;
                              font-weight: 600;
                            "
                          >
                            ACTIVE
                          </p></v-col
                        ><v-col>
                          <v-btn
                            small
                            color="secondary"
                            @click="restoreDefaultList()"
                          >
                            <v-icon>mdi-refresh</v-icon>
                          </v-btn></v-col
                        ></v-row
                      >

                      <v-select
                        v-model="value"
                        :items="forum_headers"
                        label="Select Item"
                        multiple
                        return-object
                        style="display: none"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.text }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text caption"
                            >(+{{ value.length - 1 }} others)</span
                          >
                        </template>
                      </v-select>
                      <draggable
                        :emptyInsertThreshold="100"
                        class="list-group"
                        :list="list1"
                        group="fields"
                        :sort="true"
                        v-bind="dragOptions"
                        @start="isDragging = true"
                        @end="isDragging = false"
                      >
                        <transition-group type="transition" name="flip-list">
                          <div
                            class="list-group-item dragList"
                            style="cursor: pointer; font-size: 0.85em"
                            v-for="(element, index) in list1"
                            :key="element.value"
                          >
                            <v-icon color="#ffffff">mdi-dots-vertical</v-icon>
                            {{ element.text }}
                            <span style="display: none">{{ index }}</span>
                          </div>
                        </transition-group>
                      </draggable>
                    </v-flex>
                    <v-flex
                      col
                      style="
                        color: #fff;
                        background: #4492cb;
                        min-height: 350px;
                        padding: 3em;
                      "
                    >
                      <v-row style="margin-top: -2em; margin-left: 0.5em">
                        <v-col>
                          <p
                            style="
                              color: #112748;

                              font-size: 0.8em;
                              font-weight: 600;
                            "
                          >
                            INACTIVE
                          </p></v-col
                        ><v-col>
                          <v-btn small color="secondary" @click="moveAllList()">
                            <v-icon>mdi-arrow-left-bold-box</v-icon>
                          </v-btn></v-col
                        ></v-row
                      >
                      <draggable
                        :emptyInsertThreshold="100"
                        class="list-group"
                        :list="list2"
                        group="fields"
                        :sort="true"
                        v-bind="dragOptions"
                        @start="isDragging = true"
                        @end="isDragging = false"
                      >
                        <transition-group type="transition" name="flip-list">
                          <div
                            class="list-group-item dragList"
                            style="cursor: pointer; font-size: 0.85em"
                            v-for="(element, index) in list2"
                            :key="element.value"
                          >
                            <v-icon color="#ffffff">mdi-dots-vertical</v-icon>
                            {{ element.text }}
                            <span style="display: none">{{ index }}</span>
                          </div>
                        </transition-group>
                      </draggable>
                    </v-flex>
                  </v-layout>
                </v-card>
                <template v-slot:activator="{ on }">
                  <v-btn
                    dark
                    icon
                    v-on="on"
                    style="margin-top: 0em; position: absolute; right: 10px"
                  >
                    <v-icon color="#58ABE7">mdi-reorder-vertical</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-toolbar></v-col
          >
        </v-row>

        <v-data-table
          class="forumTable"
          id="fmMainTable"
          :headers="selectedHeaders"
          :items="site_forums"
          :items-per-page="-1"
          disable-sort
          hide-default-footer
          v-model="selectedForums"
          show-select
        >
          <template v-slot:item.enabled="{ value }">
            <v-icon color="primary" class="mr-2" v-if="value"
              >mdi-check-circle
            </v-icon>
            <v-icon color="#112748" medium class="mr-2" v-if="!value"
              >mdi-checkbox-blank-circle
            </v-icon>
          </template>
        </v-data-table>
        <div style="min-height: 100px">&nbsp;</div>
        <infinite-loading
          :identifier="infiniteId"
          @infinite="infiniteHandler"
          v-if="site_forums.length > 19"
        >
          <div slot="spinner">
            <v-row>
              <div v-for="(row, index) in 6" :key="index">
                <v-skeleton-loader
                  class="mx-auto"
                  width="95vw"
                  type="text"
                  style="margin-top: 1em; margin-bottom: 1em"
                  dark
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="mx-auto"
                  width="95vw"
                  type="divider"
                  style="margin-top: 1em; margin-bottom: 1em"
                  dark
                ></v-skeleton-loader>
              </div>
            </v-row>
          </div>
          <div slot="no-more" class="body-2 mt-2">&nbsp;</div>
          <div slot="no-results" class="body-2 mt-2">&nbsp;</div>
        </infinite-loading>

        <v-fade-transition>
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            outlined
            color="white"
            @click="toTop"
          >
            <v-icon>mdi-arrow-up-bold</v-icon>
          </v-btn>
        </v-fade-transition>
      </v-row>
    </div>
    <v-snackbar
      v-model="showClipboard"
      color="#58abe7"
      centered
      :timeout="2000"
      elevation="24"
    >
      {{ showMsg }}
    </v-snackbar>
    <v-dialog
      :overlay-color="'#000000'"
      :overlay-opacity="0.9"
      v-model="openModal"
      max-width="400"
    >
      <v-card width="400" class="pr-8 pl-8 pt-4 pb-4" min-height="200">
        <v-icon class="float-right mt-3 mr-n5" @click="openModal = false"
          >mdi-close</v-icon
        >
        <v-card-title>
          <h4 class="white--text ml-n10 mb-5" style="font-weight: 100">
            Bulk {{ this.selectedAction.replace(" Selected", "") }}
          </h4>
        </v-card-title>
        <v-card-text
          >Are you sure you want to
          {{ this.selectedAction.replace(" Selected", "").toLowerCase() }} the
          selected forums? This will activate routine data
          scraping.</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="accent" @click="updateForumsSubmit('No')"
            >Cancel</v-btn
          >
          <v-btn text color="accent" @click="updateForumsSubmit('Yes')">
            {{ this.selectedAction.replace(" Selected", "") }}
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>
<script>
import { formatDate } from "@/utils/common";
import router from "@/router";
import store from "@/store";
import InfiniteLoading from "vue-infinite-loading";
import SkeletonLoader from "@/components/SkeletonLoader.vue";
import { darkReconSitesSiteIdGet } from "@/clients/tracelight";
import draggable from "vuedraggable";

export default {
  name: "DarkReconForumManager",
  components: {
    SkeletonLoader,
    InfiniteLoading,
    draggable,
  },
  data: function () {
    return {
      value: [],
      selectedHeaders: [],
      columnMenu: false,
      siteName: "",
      fab: false,
      infiniteId: 1,
      initialLoad: true,
      openModal: false,
      selectedAction: "Enable Selected",
      site_id: 0,
      page: 1,
      enabled: null,
      sort_order: "asc",
      sort_by: "title",
      toggleSort: 0,
      title: "",
      selectedForums: [],
      showClipboard: false,
      showMsg: "",
      allFieldsList: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Enabled",
          value: "enabled",
        },
        {
          text: "Date Added",
          value: "created_at",
        },
        {
          text: "Last Activity Date",
          value: "last_activity_date",
        },
        {
          text: "Last Scraped Date",
          value: "last_scraped_date",
        },
        { value: "updated_at", text: "Updated at" },
        { value: "id", text: "ID" },
      ],
      forum_headers: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Enabled",
          value: "enabled",
        },

        {
          text: "Last Activity Date",
          value: "last_activity_date",
        },
        {
          text: "Last Scraped Date",
          value: "last_scraped_date",
        },
        { value: "updated_at", text: "Updated at" },
        { value: "id", text: "ID" },
      ],
      defaultList2: [
        {
          text: "Date Added",
          value: "created_at",
        },
        { value: "updated_at", text: "Updated at" },
        { value: "id", text: "ID" },
      ],
      defaultList1: [
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Enabled",
          value: "enabled",
        },

        {
          text: "Last Activity Date",
          value: "last_activity_date",
        },
        {
          text: "Last Scraped Date",
          value: "last_scraped_date",
        },
      ],
      list1: [],
      list2: [
        {
          text: "Date Added",
          value: "created_at",
        },
        { value: "updated_at", text: "Updated at" },
        { value: "id", text: "ID" },
      ],
    };
  },
  mounted() {
    this.site_id = this.$route.params.site_id;
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    this.updateUrlParams(urlParams);
    const siteParams = { site_id: this.site_id };
    darkReconSitesSiteIdGet({}, siteParams).then((result) => {
      this.siteName = result.data.data.name;
    });

    this.list1 = [
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Enabled",
        value: "enabled",
      },

      {
        text: "Last Activity Date",
        value: "last_activity_date",
      },
      {
        text: "Last Scraped Date",
        value: "last_scraped_date",
      },
    ];
    this.selectedHeaders = this.forum_headers;
  },
  methods: {
    restoreDefaultList() {
      const defaultList1 = [...this.defaultList1];
      const defaultList2 = [...this.defaultList2];
      this.list1 = defaultList1;
      this.list2 = defaultList2;
    },
    moveAllList() {
      const allFieldsList = [...this.allFieldsList];
      this.list1 = allFieldsList;
      this.list2 = [];
    },

    updateForums(value) {
      console.log(value, this.selectedForums);
      this.selectedAction = value;
      this.openModal = true;
    },
    updateForumsSubmit(val) {
      if (val == "Yes") {
        const forumData = {
          value: this.selectedAction,
          forums: this.selectedForums,
        };
        console.log(this.selectedAction);
        store.commit("darkReconSiteManager/replaceValue", {
          key: "credentials_site_id",
          value: this.site_id,
        });
        store
          .dispatch("darkReconSiteManager/updateSiteForums", forumData)
          .then((result) => {
            console.log(result);
            this.openModal = false;
            this.showMsg = result.message;
            this.showClipboard = true;
            store.dispatch("darkReconSiteManager/getForums");
          });
      } else {
        this.openModal = false;
        return;
      }
    },
    getForums() {
      store.commit("darkReconSiteManager/replaceValue", {
        key: "forum_page",
        value: 1,
      });
      const forumData = { site_id: this.site_id };
      store.dispatch("darkReconSiteManager/getAllForums", forumData);
      this.infiniteId += 1;
      this.initialLoad = false;
    },
    updateUrlParams(urlParams) {
      for (const [key, value] of urlParams.entries()) {
        const val = urlParams.get(key);

        if (key == "sort_order") {
          store.commit("customerList/replaceValue", {
            key: "forum_sort_order",
            value: val,
          });
          this.sort_order = val;
        }

        if (key == "sort_by") {
          this.updateSortBy(val);
          this.sort_by = val;
        }
        if (key == "enabled") {
          this.updateEnabled(val);
          this.enabled = val;
        }

        if (key == "q") {
          this.q = val;
        }
      }
      this.getForums();
    },
    updateSortBy(value) {
      store.commit("darkReconSiteManager/replaceValue", {
        key: "forum_sort_by",
        value: value,
      });
      if (this.initialLoad == false) {
        this.getForums();
      }
    },
    updateEnabled(value) {
      store.commit("darkReconSiteManager/replaceValue", {
        key: "forum_enabled",
        value: value,
      });
      if (this.initialLoad == false) {
        this.getForums();
      }
    },
    changeIconColors(hover, id) {
      document.getElementById(id).classList.remove("hovering");

      if (hover == "enter") {
        document.getElementById(id).classList.add("hovering");
      }
      if (hover == "exit") {
        document.getElementById(id).classList.remove("hovering");
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 100;
    },
    toTop: function () {
      this.$vuetify.goTo(0);
    },
    infiniteHandler($state) {
      const forumData = { site_id: this.site_id };
      store.commit("darkReconSiteManager/forum_nextPage");
      store
        .dispatch("darkReconSiteManager/getAllForums", forumData)
        .then((result) => {
          if (result === null) {
            $state.loaded();
            return;
          }
          if (result.length > 0) {
            $state.loaded();
            return;
          }
          if (result.length === 0) {
            $state.complete();
            return;
          }
        });
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    totalForums: () => {
      const site_forums_total =
        store.state.darkReconSiteManager.site_forums_total || 0;
      return site_forums_total;
    },
    site_forums: () => {
      const site_forums = store.state.darkReconSiteManager.site_forums || [];
      for (const forum of site_forums) {
        forum.created_at = formatDate(forum.created_at);
        forum.last_activity_date = formatDate(forum.last_activity_date);
        forum.last_scraped_date = formatDate(forum.last_scraped_date);
        if (forum.disabled == true) {
          forum.enabled = false;
        } else {
          forum.enabled = true;
        }
      }

      return site_forums;
    },
  },
  watch: {
    list1(val) {
      this.value = val;
    },
    value(val) {
      this.selectedHeaders = val;
    },
    title: {
      handler: function (val) {
        if (val.length < 1) {
          store.commit("darkReconSiteManager/replaceValue", {
            key: "forum_title",
            value: "",
          });
        }
        if (val.length >= 1) {
          store.commit("darkReconSiteManager/replaceValue", {
            key: "forum_title",
            value: val,
          });
        }
        if (this.initialLoad == false) {
          this.getForums();
        }
      },
    },
    toggleSort: {
      handler: function (val) {
        if (val == 0) {
          this.sort_order = "asc";
        }
        if (val == 1) {
          this.sort_order = "desc";
        }

        store.commit("darkReconSiteManager/replaceValue", {
          key: "forum_sort_order",
          value: this.sort_order,
        });

        if (this.initialLoad == false) {
          this.getForums();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style scoped>
#DarkReconForumManager
  >>> table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}

#DarkReconForumManager .v-data-table {
  width: 100%;
}
#DarkReconForumManager >>> th,
#DarkReconForumManager >>> td {
  white-space: nowrap;
}

#DarkReconForumManager >>> td:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 400px;
  max-width: 400px;
}
/*
#DarkReconForumManager >>> td:nth-child(1) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 150px;
  max-width: 150px;
}

#DarkReconForumManager >>> td:nth-child(6) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 150px;
  max-width: 150px;
}
*/
.msgContent {
  padding: 0.5em;
  background-color: #112748;
  color: #e86051;
  font-size: 0.9em;
  margin-top: -1.5em;
  border-radius: 4px;
}
.hovering .v-icon {
  color: #112748 !important;
}

.list-group-item.dragList {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border-radius: 4px;
}
</style>
